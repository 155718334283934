import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue')
  },
  {
    path: '/servicos',
    name: 'servicos',
    component: () => import('../components/Servicos.vue')
  },
  {
    path: '/agendamento',
    name: 'agendamento',
    component: () => import('../components/Agendamento.vue')
  },
  {
    path: '/:id',
    name: 'Key',
    component: () => import('../components/Galeria.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior () {
    document.getElementById('app').scrollIntoView({ behavior: 'smooth' })
  }
})

// Update the document title based on the meta title and route parameter
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = `${to.params.id.replaceAll('-', ' ').replace(/(?:^|\s)\S/g, function (a) {
      return a.toUpperCase()
    })} - Guincho a preço Justo - Chegamos em 15 Minutos`
  } else {
    document.title = 'Guincho 24 Horas - Guincho a preço Justo - Chegamos em 15 Minutos'
  }
  next()
})

export default router
